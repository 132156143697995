<template>
  <mdb-container fluid class="p-0 classic-form-page">
    <mdb-navbar style="background-color: #173862;" class="justify-content-between">
      <mdb-navbar-brand href="javascript:;" v-on:click="$router.push({ name: 'Login' })">
        <img src="/img/OWG-logo.svg" height="30" alt="" v-on:click="$router.push({ name: 'Login' })">
      </mdb-navbar-brand>
      <span class="navbar-text" style="color:#e91951;font-weight: bold;float: right;">
        <a href="javascript:;" v-on:click="changelang('fr')" style="color: #e91951; text-decoration: underline;">FR</a> | <a href="javascript:;" v-on:click="changelang('en')" style="color: #e91951;">EN</a>
      </span>
    </mdb-navbar>
    <mdb-view class="">
      <mdb-mask
        overlay="stylish-strong"
        class="d-flex justify-content-center"
      >
        <mdb-container class="pt-5">
          <mdb-row>
            <div class="col-xl-6 col-lg-6 col-md-10 col-sm-12 mx-auto mt-5">
              <mdb-card id="classic-card">
                <mdb-card-body class="z-depth-2 white-text">
                  <div class="form-header" style="background-color: #173862;">
                    <h3><i class="fas fa-user mt-2 mb-2"></i> {{$t('login.change_your_password')}}:</h3>
                  </div>
                  <mdb-alert color="danger" v-if="errormsg">{{errormsg}}</mdb-alert>
                  <mdb-alert color="success" v-if="successmsg">{{successmsg}}<br /> <a href="/login">{{$t('login.login')}}</a></mdb-alert>
                  <div v-if="showform">
                    <mdb-input
                      :label="$t('login.your_new_password')"
                      labelColor="white"
                      type="password"
                      inputClass="input-form-control"
                      v-model="password"
                      @keyup.enter.native="checkforenterpressed"
                    />
                    <div class="text-center mt-4 black-text">
                      <mdb-btn style="background-color: #e91951!important;font-weight: bold;" v-on:click="modifypwd">{{$t('other.save')}}</mdb-btn>
                    </div>
                  </div>
                </mdb-card-body>
              </mdb-card>
            </div>
          </mdb-row>
        </mdb-container>
      </mdb-mask>
    </mdb-view>
  </mdb-container>
</template>

<script>
import {
  mdbNavbar,
  mdbNavbarBrand,
  mdbContainer,
  mdbView,
  mdbMask,
  mdbRow,
  mdbCard,
  mdbCardBody,
  mdbInput,
  mdbBtn,
  mdbAlert,
} from 'mdbvue';
import Auth from '../../libs/Auth';

export default {
  name: 'changePwdPage',
  data() {
    return {
      password: '',
      errormsg: '',
      successmsg: '',
      showform: false,
    };
  },
  components: {
    mdbNavbar,
    mdbNavbarBrand,
    mdbContainer,
    mdbView,
    mdbMask,
    mdbRow,
    mdbCard,
    mdbCardBody,
    mdbInput,
    mdbBtn,
    mdbAlert,
  },
  mounted() {
    this.loadrequest();
  },
  methods: {
    loadrequest() {
      const auth = new Auth();
      const self = this;
      auth.checkrequest(this.$route.params.token).then((response) => {
        if (response.status === 'OK') {
          self.showform = true;
          self.changelang(response.data.lang);
        } else {
          self.errormsg = response.msg;
        }
      });
    },
    modifypwd() {
      this.showform = false;
      this.successmsg = 'Bravo';
      const auth = new Auth();
      const self = this;
      self.successmsg = '';
      self.errormsg = '';
      self.showform = false;
      auth.changePasswordWithToken(this.$route.params.token, this.password).then((response) => {
        if (response.status === 'OK') {
          self.successmsg = response.msg;
        } else {
          self.showform = true;
          self.errormsg = response.msg;
        }
      });
    },
    checkforenterpressed(e) {
      if (e.keyCode === 13) {
        this.login();
      }
    },
    changelang(lang) {
      this.$store.state.currentlang = lang;
      this.$i18n.locale = lang;
      window.localStorage.setItem('currentlang', lang);
    },
  },
};
</script>

<style scoped>
.classic-form-page {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 10000;
  height: 100vh;
  width: 100%;
}

.classic-form-page .view {
  background-image: url("/public/images/owg-page-header_e5879cbe.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  height: 100vh;
}

.card {
  background-color: rgba(229, 228, 255, 0.2);
}

.classic-form-page h6 {
  line-height: 1.7;
}

.classic-form-page .navbar {
  transition: background 0.5s ease-in-out, padding 0.5s ease-in-out;
}

.classic-form-page .navbar .md-form {
  margin: 0;
}

.top-nav-collapse {
  background: #424f95 !important;
}

@media (max-width: 768px) {
  .classic-form-page .navbar:not(.top-nav-collapse) {
    background: #424f95 !important;
  }
}

.classic-form-page label {
  color: #fff !important;
}

</style>
<style>
.input-form-control {
  color:#FFF!important;
}
</style>
